export default class PrivatePolicy {
	private static key = 'privatepolicy';

	private static url_start = '/privatepolicy/?action=start';
	private static url_info = '/privatepolicy/?action=info';

	private static accept(): void {
		localStorage.setItem(this.key, 'accept');
	}

	private static isAccepted(): boolean {
		return localStorage.getItem(this.key) == 'accept';
	}

    private static initBar($popup: JQuery): void {
		let $show = $('.privatepolicy__show', $popup);
		let $accept = $('.privatepolicy__accept', $popup);
		let $text = $('.privatepolicy__text', $popup);

		$show.click((e) => {
			e.preventDefault();

			$text.slideDown();
		});

		$accept.click((e) => {
			e.preventDefault();

			this.accept();

			let $body = $('body');

			$popup.removeClass('privatepolicy--show');
			$body.removeClass('body--privatepolicy');

			setTimeout(() => {
				$popup.remove();
			}, 2000);
		});

		setTimeout(() => {
			$popup.addClass('privatepolicy--show');
		}, 500);
	}

	public static discover($parent?: JQuery): void {
		$('.js-privatepolicybutton', $parent).click((e) => {
			e.preventDefault();

			let $t = $(e.target);
			let type = $t.data('type');

			let url = this.url_info + '&type=' + type;

			(<any> $).fancybox({
				href: url
			});
		});
	}

	public static init(): void {
		$(() => {
			if (!this.isAccepted()) {
				$.get(this.url_start, null, (data) => {
					let $data = $(data);

					let $body = $('body');
					$body.append($data);

					$body.addClass('body--privatepolicy');

					this.initBar($data);
				});
			}
		});
	}
}
