import PrivatePolicy from "./utils/PrivatePolicy";

declare let FB;

class Site {
	public static PrivatePolicy = PrivatePolicy;

	public static discover($parent?: JQuery): void {
		PrivatePolicy.discover($parent);
	}

	public static init(): void {
		(<any> window).Site = Site;

		PrivatePolicy.init();

		$(() => {
			this.discover();
		});
	}
}

(function($) {
	$(document).ready(function() {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]')).fancybox({
			titlePosition: 'over'
		});

		const openFunc = ($el: JQuery) => {
			$el.children('ul').stop(true, true)
			.animate({ opacity: 'show', height: 'show' }, 150);
		};

		const closeFunc = ($el: JQuery) => {
			$el.children('ul').stop(true,true).delay(400)
			.animate({ opacity: 'hide', height: 'hide' }, 100);
		};

		const mx = 768;

		$('#mainmenu .children').hover(
			function() {
				if (window.innerWidth > mx) {
					openFunc($(this));
				}
			},

			function() {
				if (window.innerWidth > mx) {
					closeFunc($(this));
				}
			}
		);

		const $items = $('#mainmenu .item');

		$items.each((ix, el) => {
			const $item = $(el);

			const $submenu = $('> .submenu', $item);

			if ($submenu.length) {
				$item.on('click', (e) => {
					const wx = window.innerWidth;

					if (wx <= mx) {
						if (!$submenu.is(':visible')) {
							e.preventDefault();

							openFunc($item);
						}
					}
				});
			}
		});

		var $mainmenurwd=$('#mainmenu');

		$mainmenurwd.find('.menubutton').click(function(e) {
			e.preventDefault();

			if ($mainmenurwd.hasClass('opened')) {
				$mainmenurwd.removeClass('opened');

			} else {
				$mainmenurwd.addClass('opened');
			}
		});

		function initRWD() {
			var $body=$('body');

			function updateScroll() {
				var top=$(window).scrollTop();

				if (top>0) {
					$body.addClass('float');
				} else {
					$body.removeClass('float');
				}
			}

			function updateLayout() {
				$body.removeClass('minini');

				var by=$body.height();
				var wh=$(window).height();

				if (by<wh) {
					$body.addClass('minini');
				} else {
					$body.removeClass('minini');
				}
			}

			$(window).scroll(updateScroll);
			$(window).resize(updateLayout);
			$(window).load(updateLayout);

			$('img').load(updateLayout);
			$body.on('ajaxupdate', updateLayout);

			updateScroll();
			updateLayout();
		}

		initRWD();

	});
})(jQuery);

Site.init();
